body {
  font: 2em monospace;
  color: #D0D0CC;
  background-color: #151515; 
  display: flex;
  justify-content: center;
  padding: 20%;
}
  
  .wrapper {
    overflow: hidden;
    border-right: .15em solid rgb(75, 0, 0);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;
    /* animation-iteration-count: infinite;   */
  }

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(75, 0, 0); }
  }